import request from '@/utils/request'

/**
  * 获取所有会员类别
  */
export const getMembers = () => {
  return request({
    url: '/v2/member/getAllMember?status=1',
    method: 'GET'
  })
}

/**
 * 创建vip会员
 */
export const createVipMember = ({
  memberName,
  limitedTime,
  memberId,
  price,
  type = 'vip',
  openId
}) => {
  return request({
    url: '/v2/vip/create',
    method: 'POST',
    data: {
      member_name: memberName,
      limited_time: limitedTime,
      member_id: memberId,
      price,
      type,
      openId
    }
  })
}

/**
 * 微信支付成功/失败状态
 */
export const orderPayStatus = (payState, data) => {
  return request({
    url: '/v2/vip/payState',
    method: 'POST',
    data: {
      payState,
      member_name: data.memberName,
      member_id: data.memberId,
      price: data.price,
      order_number: data.orderNumber,
      limited_time: data.limitedTime
    }
  })
}

/**
 * 获取vip用户列表
 */
export const vipStatus = () => {
  return request({
    url: '/v2/vip/status',
    method: 'GET'
  })
}
