/*
 * @Author: Musa Tabitay
 * @Date: 2022-04-06 21:16:05
 * @LastEditTime: 2022-04-09 16:00:40
 */

import request from '@/utils/request'

// 登录
export const getToken = async data => {
  return request({
    method: 'POST',
    url: '/v2/token',
    data
  })
}

// 微信用户授权回调地址
export const wechatRedirect = '/h5/redirect'

// // 获取用户信息
// export const getUserInfo = () => {
//   return request({
//     method: 'GET',
//     url: '/h5/getUserInfo'
//   })
// }

// 获取微信JSSDK
export const jssdk = () => {
  return request({
    method: 'GET',
    url: '/h5/jssdk',
    params: {
      url: location.href.split('#')[0]
    }
  })
}

// 订单列表
export const getMyBuyOrder = async (params) => {
  return request({
    method: 'GET',
    url: '/v2/wxpay/getMyBuyOrder',
    data: params
  })
}

// 收藏列表
export const myCollections = async () => {
  return request({
    method: 'GET',
    url: '/v2/collection/my_collec'
  })
}

// 收藏
export const colAdd = async id => {
  return request({
    method: 'POST',
    url: `/v2/collection/${id}/104/add`
  })
}

// 取消收藏
export const colCancel = async id => {
  return request({
    method: 'POST',
    url: `/v2/collection/${id}/104/cancel`
  })
}

// 收藏状态
export const collecStatus = async id => {
  return request({
    method: 'GET',
    url: `/v2/collection/${id}/104/collec_status`
  })
}

// 查询用户信息
export const getUserInfo = async openId => {
  return request({
    method: 'POST',
    url: '/v2/user/info',
    params: {
      openid: openId
    }
  })
}
